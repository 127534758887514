import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Question, QuestionOption, Topic } from "../common/models";
import { RootState } from "../../app/store";

export interface TopicState {
    topics: Topic[];
    questions: Question[];
    questionOptions: QuestionOption[];
    hydratedTopics?: Topic[];
    fullyLoaded: boolean;
}

const initialState: TopicState = {
    topics: [],
    questions: [],
    questionOptions: [],
    fullyLoaded: false
};

export const getTopics = createAsyncThunk(
    'topic/fetchTopics',
    async() => {
        const response = await fetch('/data-api/rest/Topic');
        return (await response.json()).value;
    }
);

export const getQuestions = createAsyncThunk(
    'topic/fetchQuestions',
    async() => {
        const response = await fetch('/data-api/rest/Question');
        return (await response.json()).value;
    }
)

export const getQuestionOptions = createAsyncThunk(
    'topic/fetchQuestionOptions',
    async() => {
        const response = await fetch('/data-api/rest/QuestionOption');
        return (await response.json()).value;
    }
)

export const topicSlice = createSlice({
    name: 'topic',
    initialState,
    reducers: {
        setHydratedTopics: (state, action) => {
            state.fullyLoaded = true;
            state.hydratedTopics = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getTopics.pending, (state, action) => {
            })
            .addCase(getTopics.fulfilled, (state, action: PayloadAction<Topic[]>) => {
                state.topics = action.payload.sort((a, b) => a.Priority - b.Priority);
            })
            .addCase(getQuestions.fulfilled, (state, action: PayloadAction<Question[]>) => {
                state.questions = action.payload.sort((a, b) => a.Priority - b.Priority);
            })
            .addCase(getQuestionOptions.fulfilled, (state, action: PayloadAction<QuestionOption[]>) => {
                state.questionOptions = action.payload.sort((a, b) => a.Priority - b.Priority);
            })
    }
});

export const { setHydratedTopics } = topicSlice.actions;

export const selectTopic = (state: RootState) => state.topic;

export default topicSlice.reducer;