import { VideoIndexes } from "../features/common/models";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { loop, play } from "../features/video/videoSlice";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useNavigate } from 'react-router-dom';

export function WelcomePage() {
    const { preloadComplete, currentVideo, ended } = useSelector((state: RootState) => state.video);
    const [waitingForVideo, setWaitingForVideo] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (preloadComplete) {
            dispatch(loop(VideoIndexes.IntroLoop));
        }
    }, [preloadComplete, dispatch])
    
    useEffect(() => {
        if (waitingForVideo && currentVideo === VideoIndexes.Welcome && ended) {
            navigate('/register');
        }
    }, [currentVideo, ended, dispatch, navigate, waitingForVideo]);

    const clickHandler = useCallback(() => {
        setWaitingForVideo(true);
        dispatch(play(VideoIndexes.Welcome));
    }, [dispatch]);

    return (
        <div className="flexContainer welcome" onClick={clickHandler}>
        
            {/* <div className="flexHeader" style={{ height: "35%" }}>
                <div className="mainLogoContainer" />
            </div> */}
        </div>
    );
}

export default WelcomePage;
