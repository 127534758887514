import { VideoIndexes } from "../common/models";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface Bounds {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface VideoState {
    preloadComplete: boolean;
    currentVideo?: VideoIndexes;
    playing: boolean;
    looping: boolean;
    ended: boolean;
    paused: boolean;
    hasStarted: boolean;
    transparent: boolean;
    bounds: Bounds;
    fadedIn: boolean;
    fadedOut: boolean;
    fading: boolean;
    faderOpacity: number;
    canPlay: boolean;
    loadedData: boolean;
    hidden: boolean;
}

const initialState: VideoState = {
    preloadComplete: false,
    playing: false,
    looping: false,
    ended: false,
    paused: false,
    hasStarted: false,
    transparent: true,
    bounds: { x: 0, y: 0, width: 100, height: 100 },
    fadedIn: false,
    fadedOut: false,
    fading: false,
    faderOpacity: 0,
    canPlay: false,
    loadedData: false,
    hidden: false
};

const fader: HTMLElement | null = document.getElementById('faderElement');
// const videoOverlay: HTMLElement | null = document.getElementById('videoOverlay');

export interface fadeOutRequest {
    addClass?: string;
    removeClass?: string;
}

export const fadeIn = createAsyncThunk(
    'video/fadeIn',
    async(className: string | undefined, { dispatch }) => new Promise<any>((resolve) => {
        const fadeInHandler = (ev: Event) => {
            fader?.classList.remove('fadeIn');
            fader?.classList.add('opaque');
            fader?.removeEventListener('animationend', fadeInHandler);
            dispatch(videoSlice.actions.show());
            resolve(true);
        };
        
        dispatch(videoSlice.actions.hide());
        fader?.addEventListener('animationend', fadeInHandler);
        if (className) {
            fader?.classList.add(className);
        } else {
            fader?.setAttribute('class', 'fader');
        }
        fader?.classList.add('fadeIn');
        fader?.classList.remove('transparent');
    })
);

export const fadeOut = createAsyncThunk(
    'video/fadeOut',
    async(request: fadeOutRequest, { dispatch }) => new Promise<any>((resolve) => {
        const fadeOutHandler = (ev: Event) => {
            fader?.classList.remove('fadeOut');
            fader?.classList.add('transparent');
            fader?.removeEventListener('animationend', fadeOutHandler);
            
            
            resolve(true);
        };
        dispatch(videoSlice.actions.hide());
        if (request.removeClass) {
            fader?.classList.remove(request.removeClass);
        } else {
            fader?.setAttribute('class', 'fader');
        }
        if (request.addClass) {
            fader?.classList.add(request.addClass);
        }
        fader?.addEventListener('animationend', fadeOutHandler);
        fader?.classList.add('fadeOut');
        fader?.classList.remove('opaque');
    })
)

export const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        completePreload: (state) => {
            state.preloadComplete = true;
        },  
        play: (state, action: PayloadAction<VideoIndexes>) => {
            state.currentVideo = action.payload;
            state.playing = true;
            state.looping = false;
            state.ended = false;

        },
        loop: (state, action: PayloadAction<VideoIndexes>) => {
            state.currentVideo = action.payload;
            state.playing = true;
            state.looping = true;
            state.ended = false;
            state.hidden = false;
        },
        stop: (state) => {
            state.playing = false;
        },
        show: (state) => {
            state.hidden = false;
        },
        hide: (state) => {
            state.hidden = true;
        },
        setTransparency: (state, action: PayloadAction<boolean>) => {
            state.transparent = action.payload;
        },
        setBounds: (state, action: PayloadAction<Bounds>) => {
            state.bounds = action.payload;
        },
        reset: (state) => {
            state.currentVideo = undefined;
            state.playing = false;
            state.looping = false;
            state.ended = false;
            state.hidden = false;
        },
        setOpacity: (state, action: PayloadAction<number>) => {
            state.faderOpacity = action.payload;
        },
        updateState: (state, action: PayloadAction<Partial<VideoState>>) => {
            state.ended = !!action.payload.ended;
        }
    },
    // extraReducers: (builder) => {
    //     builder.addCase(fadeIn.pending, (state) => {
    //         state.fading = true;
    //     }),
    //     builder.addCase(fadeIn.fulfilled, (state) => {
    //         state.fadedIn = true;
    //         state.fadedOut = false;
    //         state.fading = false;
    //     }),
    //     builder.addCase(fadeOut.pending, (state) => {
    //         state.fading = true;
    //     }),
    //     builder.addCase(fadeOut.fulfilled, (state) => {
    //         state.fadedOut = true;
    //         state.fadedIn = false;
    //         state.fading = false;
    //     })
    // }
});

export const { completePreload, play, loop, stop, show, hide, setTransparency, setBounds, reset, updateState } = videoSlice.actions;

export const selectVideo = (state: RootState) => state.video;

export default videoSlice.reducer;