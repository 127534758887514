import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import commonReducer from '../features/common/commonSlice';
import videoReducer from '../features/video/videoSlice';
import topicReducer from '../features/topic/topicSlice';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    video: videoReducer,
    topic: topicReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
