import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { fadeOut, hide, reset, fadeIn } from "../features/video/videoSlice";
import { Select, Button, MenuItem, Stack, Alert } from "@mui/material";
import "react-simple-keyboard/build/css/index.css";
import { useNavigate } from "react-router";
import NextButton from '../assets/nextButton.png';
import { createNewEntry } from "../features/common/commonSlice";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
export function RegisterPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedLender, setSelectedLender] = useState<number>(-1);
    const [selectedPosition, setSelectedPosition] = useState<string>('');
    const [alertText, setAlertText] = useState<string | undefined>(undefined);
    
    const { lenders } = useSelector((s: RootState) => s.common);

    useEffect(() => {
        dispatch(hide());   
        dispatch(fadeOut({removeClass: 'welcome'})).then(() => {
            dispatch(reset());
        });
    }, [dispatch]);

    const selectChangeHandler = useCallback((e: any) => {
        setAlertText('');
        setSelectedLender(e.target.value);
    }, []);

    const positionChangeHandler = useCallback((e: any) => {
        setAlertText('');
        setSelectedPosition(e.target.value);
    }, [])

    const getLenders = useCallback(() => {
        if (lenders && lenders.length > 0) {
            return (
                lenders.map((l) => {
                    return <MenuItem sx={{fontSize: '3em'}} key={l.Id} value={l.Id}>{l.Name}</MenuItem>
                })
            );
        }
        return [];
    }, [lenders]);

    const getPositions = useCallback(() => {
        const positions = [
            'DUS Leadership',
            'Origination', 
            'Underwriting',
            'Asset Management/Servicing'
        ]
        return (
            positions.map((p) => {
                return <MenuItem sx={{fontSize: '3em'}} key={p} value={p}>{p}</MenuItem>
            })
        );
    }, [])

    const validate = useCallback((): boolean => {
        if (selectedLender !== -1 && selectedPosition !== '') {
            return true;
        }
        return false;
    }, [selectedLender, selectedPosition]);

    const nextClickedHandler = useCallback(() => {
        if (!validate()) {
            setAlertText('Please select what best describes you.');
            return;
        }
        dispatch(createNewEntry({lenderId: selectedLender, position: selectedPosition}));
        dispatch(fadeIn('') as any).then(() => {
            navigate('/topicSelect');
        });
    }, [validate, dispatch, navigate, selectedLender, selectedPosition]);

    return (
        <Stack style={{color: 'white'}} alignItems={'center'}>
            <h1 className={'hello'} style={{ marginBottom: 0, textAlign: "center" }}>Hello!</h1>
            <h3 className={'tellUs'} style={{ marginTop: -15, fontWeight: 400 }}>
                Please tell us about yourself
            </h3>
            
            <div style={{ width: "70%", color: '05314D', marginBottom: '2em'}}>
                <p className={'selectLabel'}>Share Your Position</p>
                <Select
                    fullWidth
                    placeholder="Click to select from below"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedPosition}
                    label="name"
                    onChange={positionChangeHandler}
                    SelectDisplayProps={{
                        color: '#05314D',
                        style: {fontWeight: 700}
                    }}
                    MenuProps={{
                        sx: {
                            "&& .MuiMenuItem-root": {
                                color: '#05314D',
                                fontWeight: 700
                            }
                        }
                    }}
                    sx={{
                        fontSize: '3em',
                        backgroundColor: "white", 
                    }}
                >
                    {getPositions()}
                </Select>
            </div>
            <div style={{ width: "70%", color: '05314D'}}>
                <p className={'selectLabel'}>Select Your Company</p>
                <Select
                    fullWidth
                    placeholder="Click to select from below"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedLender >= 0 ? selectedLender : ''}
                    label="Lender"
                    onChange={selectChangeHandler}
                    SelectDisplayProps={{
                        color: '#05314D',
                        style: {fontWeight: 700}
                    }}
                    MenuProps={{
                        sx: {
                            "&& .MuiMenuItem-root": {
                                color: '#05314D'
                            }
                        }
                    }}
                    sx={{
                        color: '#05314D',
                        fontSize: '3em',
                        backgroundColor: "white"
                    }}
                >
                    {getLenders()}
                </Select>
            </div>
            {alertText && <Alert style={{width: '80%', color: '#05314D', fontSize: '2em', marginTop: '1em'}} severity={'warning'}>{alertText}</Alert>}
            <div style={{ position: 'absolute', bottom: 0, width: '100%', height: '20%', display: 'flex', alignItems: 'center', justifyItems: 'center', margin: '0 auto' }}>
                <Button fullWidth onClick={nextClickedHandler}>
                    <img alt={'Next'} src={NextButton} style={{ width: '25%', height: 'auto' }} />
                </Button>
            </div>
        </Stack>
        
    );
}

export default RegisterPage;
