import { useEffect, useMemo } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { WelcomePage } from "./pages/Welcome";
import VideoOverlay from "./features/video/VideoOverlay";
import RegisterPage from "./pages/Register";
import TopicSelect from "./pages/TopicSelect";
import { Questionnaire } from "./pages/Questionnaire";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionOptions, getQuestions, getTopics, setHydratedTopics } from "./features/topic/topicSlice";
import { RootState } from "./app/store";
import { Question, QuestionOption, Topic } from "./features/common/models";
import TopicSummary from "./pages/TopicSummary";
import { ThankYou } from "./pages/ThankYou";
import { useIdleTimer } from 'react-idle-timer'
import { loadConfig, loadLenders } from "./features/common/commonSlice";

function App() {
  const dispatch = useDispatch();
  const { config, lenders } = useSelector((s: RootState) => s.common);
  const { topics, questions, questionOptions, hydratedTopics, fullyLoaded } = useSelector((s: RootState) => s.topic);

  const timeout = useMemo(() => {
    if (config) {
      console.dir(config);
      const value = config.find(c => c.Key === 'timeout')?.Value;
      if (value) {
        return parseInt(value);
      }
    }
  }, [config]);


  useIdleTimer({
    timeout: timeout ?? 15000,
    onIdle() {
      if (window.location.pathname !== '/') {
        window.location.pathname = '/';
      }
    },
  });

  useEffect(() => {
    if (!config) {
      dispatch(loadConfig() as any);
    }

    if (!lenders) {
      dispatch(loadLenders() as any);
    }

    if (!topics?.length) {
      
      dispatch(getTopics() as any);
    }
    if (!questions.length) {
      dispatch(getQuestions() as any);
    }
    if (!questionOptions.length) {
      dispatch(getQuestionOptions() as any);
    }

  }, [config, topics, questions, questionOptions, hydratedTopics, lenders, dispatch]);

  useEffect(() => {
    if (!hydratedTopics && topics && topics.length > 0 && questions &&  questions.length > 0 && questionOptions && questionOptions.length > 0) {
      const topicsClone: Topic[] = JSON.parse(JSON.stringify(topics));
      const questionsClone: Question[] = JSON.parse(JSON.stringify(questions));
      topicsClone.forEach((t) => {
        const childQuestions: Question[] = questionsClone.filter((v) => v.Active && v.TopicId === t.Id);
        childQuestions.sort((a, b) => a.Priority - b.Priority);
        childQuestions.forEach((q) => {
          const childOptions: QuestionOption[] = questionOptions.filter((v) => v.Active && v.QuestionId === q.Id);
          childOptions.sort((a, b) => a.Priority - b.Priority);

          q.Options = childOptions;
        });


        t.Questions = childQuestions;


      });
      dispatch(setHydratedTopics(topicsClone));
    }
  }, [dispatch, topics, questions, questionOptions, hydratedTopics, fullyLoaded]);


  return (
    <div className="pageContainer">
      <VideoOverlay />
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={WelcomePage} />
          <Route path="/register" Component={RegisterPage} />
          <Route path="/topicSelect" Component={TopicSelect} />
          <Route path="/topicSummary/:topicId" Component={TopicSummary} />
          <Route path="/questionnaire/:topicId/:questionIndex" Component={Questionnaire} />
          <Route path="/thankyou" Component={ThankYou} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
