import { useCallback, useEffect, useMemo, useState } from "react";
// import { ControlBar, Player, PlayerReference, PlayerState } from "video-react";
import { VideoIndexes } from "../common/models";
import { useAppDispatch } from "../../app/hooks";
import { useSelector } from "react-redux";
import { completePreload, updateState } from "./videoSlice";
import { RootState } from "../../app/store";
import transparentPoster from '../../assets/transparent_1080px.png';
import welcomePoster from '../../assets/welcomeTransitionFrame.png';
// import questionnairePoster from '../../assets/questionPoster.png';

export function VideoOverlay() {

    const [player, setPlayer] = useState<HTMLVideoElement | null>();
    const { preloadComplete, currentVideo, playing, looping, ended, paused, hasStarted, canPlay, loadedData, hidden } = useSelector((state: RootState) => state.video);
    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState<Blob[]>([]);
    const [videoUrlIndex, setVideoUrlIndex] = useState<VideoIndexes | undefined>(undefined);

    const [videoUrl, setVideoUrl] = useState<string>();
    const dispatch = useAppDispatch();



    useEffect(() => {
        const preload = async () => {
            console.log("In Preload");
            var videoLoop = await fetch("/video/DUS_CityLoop-ScreenSaver.mp4");
            var videoLoopBlob = await videoLoop.blob();
            videos[VideoIndexes.IntroLoop] = videoLoopBlob;

            var videoWelcome = await fetch("/video/DUS_WelcomeTransition.mp4");
            var videoWelcomeBlob = await videoWelcome.blob();
            videos[VideoIndexes.Welcome] = videoWelcomeBlob;

            var videoQ1 = await fetch("/video/DUS_QuestionaireStart.mp4");
            var videoQ1Blob = await videoQ1.blob();
            videos[VideoIndexes.Question1] = videoQ1Blob;

            var videoQ2 = await fetch("/video/DUS_ToQuestion2.mp4");
            var videoQ2Blob = await videoQ2.blob();
            videos[VideoIndexes.Question2] = videoQ2Blob;

            var videoQ3 = await fetch("/video/DUS_ToQuestion3.mp4");
            var videoQ3Blob = await videoQ3.blob();
            videos[VideoIndexes.Question3] = videoQ3Blob;

            var videoQ4 = await fetch("/video/DUS_ToQuestion4.mp4");
            var videoQ4Blob = await videoQ4.blob();
            videos[VideoIndexes.Question4] = videoQ4Blob;

            var videoQ5 = await fetch("/video/DUS_ToQuestion5.mp4");
            var videoQ5Blob = await videoQ5.blob();
            videos[VideoIndexes.Question5] = videoQ5Blob;

            setVideos(videos);
        };
        const callPreload = async () => {
            await preload();
        };

        if (!preloadComplete && !loading) {
            setLoading(true);
            callPreload().then(() => {
                dispatch(completePreload());
                setLoading(false);
            });

        }
    }, [preloadComplete, dispatch, loading, videos]);

    useEffect(() => {
        if (currentVideo !== videoUrlIndex || typeof videoUrl === 'undefined') {
            if (videoUrl) {
                URL.revokeObjectURL(videoUrl);
            }
            if (typeof currentVideo === 'undefined') {
                setVideoUrl(undefined);
                if (player) player.currentTime = 0;
            } else {
                const blob = videos[currentVideo];
                if (blob) {
                    setVideoUrlIndex(currentVideo);
                    setVideoUrl(URL.createObjectURL(blob));
                }
            }
        }

    }, [player, currentVideo, videoUrl, videos, videoUrlIndex]);

    useEffect(() => {
        if (player && looping !== player.loop) {
            player.loop = looping;
        }
        

    }, [looping, ended, paused, hasStarted, player]);

    useEffect(() => {
        if (player && playing && !hasStarted && !ended && canPlay && loadedData) {
            player?.play();

        }

    }, [playing, player, ended, canPlay, loadedData, hasStarted]);

    // const onPlayHandler = useCallback((e: React.SyntheticEvent<HTMLVideoElement>) => {
    //     dispatch(updateState({playing: true, ended: false, paused: false, hasStarted: e.currentTarget.currentTime > 0}));
    // }, [dispatch]);

    const onPauseHandler = useCallback((e: React.SyntheticEvent<HTMLVideoElement>) => {
        dispatch(updateState({ paused: true, playing: false, hasStarted: e.currentTarget.currentTime > 0 }))
    }, [dispatch]);

    const onEndedHandler = useCallback((e: React.SyntheticEvent<HTMLVideoElement>) => {
        dispatch(updateState({ ended: true }));
        if (!looping) {
            //dispatch(reset());
        }
    }, [dispatch, looping]);

    const onCanPlayHandler = useCallback((e: React.SyntheticEvent<HTMLVideoElement>) => {
        dispatch(updateState({ canPlay: true }));
    }, [dispatch]);

    const onLoadedDataHandler = useCallback((e: React.SyntheticEvent<HTMLVideoElement>) => {
        dispatch(updateState({ loadedData: true }));
    }, [dispatch]);

    const currentPoster = useMemo(() => {
        if (ended) {
            return transparentPoster;
        }
        switch (currentVideo) {
            case VideoIndexes.Welcome:
                return welcomePoster;
            case VideoIndexes.Question1:
            case VideoIndexes.Question2:
            case VideoIndexes.Question3:
            case VideoIndexes.Question4:
            case VideoIndexes.Question5:
                // return questionnairePoster;
                return undefined;
            default: 
                return transparentPoster;
        }
    }, [currentVideo, ended])

    const visible = !hidden && (typeof currentVideo !== 'undefined') && (looping || (!looping && !ended));
    return (
        <>
            <div className="videoOverlay" id={'videoOverlay'} style={{ display: (visible ? 'block' : 'none') }}>
                <video style={{ backgroundColor: 'transparent' }}
                    width={document.body.clientWidth}
                    height={document.body.clientHeight}
                    muted={true}
                    playsInline={true}
                    autoPlay={true}
                    ref={(video) => setPlayer(video)}
                    poster={currentPoster}
                    // onPlay={onPlayHandler}
                    onPause={onPauseHandler}
                    onEnded={onEndedHandler}
                    onCanPlay={onCanPlayHandler}
                    onLoadedData={onLoadedDataHandler}
                    src={videoUrl}
                >

                </video>
                {/* <Player
                    width={document.body.clientWidth}
                    height={document.body.clientHeight}
                    muted
                    playsInline={true}
                    preload="auto"
                    ref={(player) => setPlayer(player)}
                    src={videoUrl}
                >
                    <ControlBar disableCompletely={true} />
                </Player> */}
            </div>
        </>
    );
}

export default VideoOverlay;
