import { Stack } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useAppDispatch } from "../app/hooks";
import { fadeOut } from "../features/video/videoSlice";
import restart from '../assets/restart.png';
import { useNavigate } from "react-router";
import { createNewEntry } from "../features/common/commonSlice";

export function ThankYou() {
    // const { fading } = useSelector((s: RootState) => s.video);
    const { entry } = useSelector((s: RootState) => s.common);
    const dispatch = useAppDispatch();
    const navigator = useNavigate();

    useEffect(() => {
        dispatch(fadeOut({removeClass: 'welcome'}) as any);
    }, [dispatch]);

    const restartClick = useCallback(() => {
        navigator('/');
    }, [navigator]);

    const selectTopicClick = useCallback(() => {
        dispatch(createNewEntry({ lenderId: entry?.LenderId, position: entry?.Position }));
        navigator('/topicSelect');
    }, [dispatch, entry, navigator]);

    return (
        <Stack className={'thankYouContainer'}>
            <div style={{ position: 'absolute', backgroundColor: 'transparent', zIndex: 1, color: 'white', textDecoration: 'underline' , textAlign: 'center', fontSize: 'calc(var(--bodyHeight) * .03px)', top: 'calc(var(--bodyHeight) * .35px)', left: 'calc(var(--bodyWidth) * .1px)', width: 'calc(var(--bodyWidth) * .8px)', height: 'calc(var(--bodyHeight) * .05px)'}} onClick={selectTopicClick}>Click here to go to another topic</div>
            <div style={{
                position: 'absolute',
                top: '45%',
                width: '20%',
                left: '40%',
            }}>
                <div style={{margin: '0 auto'}}>
                    <img alt={'Home'} src={restart} style={{width: '100%', height: 'auto'}} onClick={restartClick}/>
                </div>
            </div>
        </Stack>
    )
}