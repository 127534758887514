import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { RootState } from "../app/store";
import { fadeIn, fadeOut, play, reset } from '../features/video/videoSlice';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { QuestionType, VideoIndexes } from "../features/common/models";
import { Alert, Button, Checkbox, FormControlLabel, Stack, TextareaAutosize } from "@mui/material";
import NextButton from '../assets/nextButton.png';
import BackButton from '../assets/backButton.png';
import { EntryAnswer, resetAnswer, saveEntryAsync, selectCurrentQuestion, submitAnswer } from "../features/common/commonSlice";
import Keyboard from 'react-simple-keyboard';

export function Questionnaire() {
    const { topicId, questionIndex } = useParams();
    const { entry } = useSelector((s: RootState) => s.common);
    const { ended, fading } = useSelector((s: RootState) => s.video);
    const [answers, setAnswers] = useState<EntryAnswer[]>([]);
    const [keyboardLayoutName, setKeyboardLayoutName] = useState('default');
    const [alertText, setAlertText] = useState<string | undefined>(undefined);

    const currentQuestion = useSelector((s: RootState) => selectCurrentQuestion(s, topicId, questionIndex));

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (entry && entry.EntryAnswers && currentQuestion && (currentQuestion.Id in entry.EntryAnswers)) {
            setAnswers(entry.EntryAnswers[currentQuestion.Id]);
        }
    }, [entry, currentQuestion]);

    useEffect(() => {
        if (ended && !fading) {
            // dispatch(hide());
            if (questionIndex === '1') {
                dispatch(fadeOut({ addClass: 'questionnaire' }) as any);
            } else {
                dispatch(fadeOut({ addClass: 'questionnaire' }) as any);
            }


        }
    }, [currentQuestion, ended, dispatch, fading, questionIndex]);

    const handleBack = useCallback(() => {
        const questionIndexNumber = parseInt(questionIndex!);
        dispatch(reset());
        switch (questionIndexNumber) {
            case 1:
                navigate(`/topicSummary/${topicId}`);
                break;
            case 2:
                navigate(`/questionnaire/${topicId}/${1}`);
                break;
            case 3:
                navigate(`/questionnaire/${topicId}/${2}`);
                break;
            case 4:
                navigate(`/questionnaire/${topicId}/${3}`);
                break;
            case 5:  // Go To Thank You
                navigate(`/questionnaire/${topicId}/${4}`);
                break;
        }
    }, [dispatch, topicId, questionIndex, navigate]);

    const validate = useCallback((): boolean => {
        if (currentQuestion) {
            const existingAnswers = answers.filter(a => a.QuestionId === currentQuestion.Id);
            if (existingAnswers && existingAnswers.length > 0) {
                return true;
            }
        }
        return false;
    }, [currentQuestion, answers]);


    const handleNext = useCallback(() => {
        if (!validate()) {
            setAlertText('Please select an answer');
            return;
        }

        if (!answers) {
            console.warn(`No answer found for ${currentQuestion?.Priority}.`);
            return;
        }

        console.dir(answers);
        dispatch(resetAnswer(currentQuestion!.Id));
        for (var i = 0; i < answers.length; i++) {
            dispatch(submitAnswer(answers[i]));
        }

        dispatch(reset());
        const questionIndexNumber = parseInt(questionIndex!);
        switch (questionIndexNumber) {
            case 1:
                dispatch(fadeIn('questionnaire') as any).then(() => {
                    dispatch(play(VideoIndexes.Question2));
                    setAnswers([]);
                    navigate(`/questionnaire/${topicId}/${2}`);
                });

                break;
            case 2:
                dispatch(fadeIn('questionnaire') as any).then(() => {
                    dispatch(play(VideoIndexes.Question3));
                    setAnswers([]);
                    navigate(`/questionnaire/${topicId}/${3}`);
                });

                break;
            case 3:
                dispatch(fadeIn('questionnaire') as any).then(() => {
                    dispatch(play(VideoIndexes.Question4));
                    setAnswers([]);
                    navigate(`/questionnaire/${topicId}/${4}`);
                });

                break;
            case 4:
                dispatch(fadeIn('questionnaire') as any).then(() => {
                    dispatch(play(VideoIndexes.Question5));
                    setAnswers([]);
                    navigate(`/questionnaire/${topicId}/${5}`);
                });

                break;
            case 5:  // Go To Thank You
                if (entry) {
                    dispatch(fadeIn('questionnaire') as any).then(() => {
                        dispatch(saveEntryAsync({ entry, additionalAnswers: answers }) as any).then(() => {
                            setAnswers([]);
                            navigate('/thankYou');
                        });
                    });
                } else {
                    console.log('No entry found when trying to save?');
                }
                break;
        }
    }, [topicId, questionIndex, currentQuestion, answers, dispatch, navigate, entry, validate]);


    const addAnswer = useCallback((list: EntryAnswer[], answer: EntryAnswer) => {
        const newAnswers = list.concat([]);
        newAnswers.push(answer);
        setAlertText(undefined);
        return newAnswers;
    }, []);


    const removeAnswer = useCallback((list: EntryAnswer[], answer: EntryAnswer) => {
        const newAnswers = [];
        for (var i = 0; i < list.length; i++) {
            const currentAnswer = answers[i];
            if (currentAnswer.QuestionId === answer.QuestionId && (!answer.QuestionOptionId || answer.QuestionOptionId === currentAnswer.QuestionOptionId)) {

            } else {
                newAnswers.push(currentAnswer);
            }
        }
        return newAnswers;
    }, [answers]);

    const selectOptionHandler = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const selectedId = parseInt(e.currentTarget.id);
        var updatedAnswers = removeAnswer(answers, { QuestionId: currentQuestion!.Id });
        updatedAnswers = addAnswer(updatedAnswers, {
            QuestionId: currentQuestion!.Id,
            QuestionOptionId: selectedId,
        });
        setAnswers(updatedAnswers);
    }, [currentQuestion, answers, addAnswer, removeAnswer, setAnswers])

    const singleSelectChecked = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const intId = parseInt(e.target.id);
        const currentAnswer = {
            QuestionId: currentQuestion!.Id,
            QuestionOptionId: intId
        };
        var updatedAnswers = removeAnswer(answers, { QuestionId: currentQuestion!.Id });
        if (checked) {
            updatedAnswers = addAnswer(updatedAnswers, currentAnswer);
        } else {
            updatedAnswers = removeAnswer(updatedAnswers, currentAnswer);
        }
        setAnswers(updatedAnswers);
    }, [currentQuestion, answers, addAnswer, removeAnswer, setAnswers]);

    const multiSelectItemChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const intId = parseInt(event.target.id);
        const currentAnswer = {
            QuestionId: currentQuestion!.Id,
            QuestionOptionId: intId
        }
        var updatedAnswers = answers.concat([]);
        if (checked) {
            updatedAnswers = addAnswer(answers, currentAnswer);
        } else {
            updatedAnswers = removeAnswer(answers, currentAnswer);
        }
        setAnswers(updatedAnswers);
    }, [currentQuestion, answers, addAnswer, removeAnswer, setAnswers]);

    const textEntered = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        const currentAnswer: EntryAnswer = {
            QuestionId: currentQuestion!.Id,
            EnteredText: e.target.value
        }
        var updatedAnswers = removeAnswer(answers, currentAnswer);
        updatedAnswers = addAnswer(updatedAnswers, currentAnswer);
        setAnswers(updatedAnswers);
    }, [currentQuestion, answers, addAnswer, removeAnswer, setAnswers]);

    // KEYBOARD

    const onChangeHandler = useCallback(
        (input: string) => {
            console.dir(input);
            const currentAnswer: EntryAnswer = {
                QuestionId: currentQuestion!.Id,
                EnteredText: input
            }
            var updatedAnswers = removeAnswer(answers, currentAnswer);
            updatedAnswers = addAnswer(updatedAnswers, currentAnswer);
            setAnswers(updatedAnswers);
        }, [addAnswer, answers, currentQuestion, removeAnswer]
    );

    const onKeyPressHandler = useCallback(
        (button: string) => {
            console.log(button);
            console.log(button === '{shift}');
            switch (button) {
                case "{shift}":
                    if (keyboardLayoutName === "shift") {
                        setKeyboardLayoutName("default");
                    } else {
                        setKeyboardLayoutName("shift");
                    }
                    break;
            }
        },
        [keyboardLayoutName]
    );

    const renderedQuestion = useMemo(() => {
        if (currentQuestion) {

            const keyboardLayout = {
                default: [
                    "` 1 2 3 4 5 6 7 8 9 0",
                    "q w e r t y u i o p",
                    "a s d f g h j k l",
                    "{shift} z x c v b n m , . ? {bksp}",
                    "{space} {enter}",
                ],
                shift: [
                    "` ! @ # $ % ^ & * ( )",
                    "Q W E R T Y U I O P",
                    "A S D F G H J K L",
                    "{shift} Z X C V B N M , . ? {bksp}",
                    "{space} {enter}",
                ],
            };

            const keyboardDisplay = {
                "{shift}": "\u21e7",
                "{bksp}": "\u232b",
                "{enter}": "\u23ce",
                "{space}": "\u2423",
            };

            const existingAnswers = answers.filter(a => a.QuestionId === currentQuestion.Id);
            let options;
            switch (currentQuestion.Type) {
                case QuestionType.CheckboxMultiSelect:
                    console.log('Checkbox Multi Select');
                    options = currentQuestion.Options;
                    if (options) {
                        return (
                            <Stack style={{ width: '70%', alignItems: 'center', fontSize: '2.5em' }}>
                                {options.map((o) => {
                                    const existingAnswer = existingAnswers && existingAnswers.length > 0 && existingAnswers.find(e => e.QuestionOptionId === o.Id);
                                    const checked = !!existingAnswer;
                                    return <FormControlLabel sx={{ width: '100%', backgroundColor: 'transparent', color: 'white', fontSize: 'calc(var(--bodyHeight) * .05px)', lineHeight: '2.5em', marginBottom: 'calc(var(--bodyHeight) * .02px)' }} key={o.Id} control={<Checkbox checked={checked} value={checked}  className={'solidCheckbox'} id={o.Id.toString()} onChange={multiSelectItemChecked} sx={{ color: 'white', '& .MuiSvgIcon-root': { fontSize: '1em', color: 'white' } }} />} label={<span className={'multiCheckboxLabel'}>{o.Text}</span>} />
                                }
                                )}
                            </Stack>
                        );
                    }
                    break;
                case QuestionType.CheckboxSingleSelect:
                    console.log('Checkbox Single Select');
                    options = currentQuestion.Options;
                    if (options) {

                        return (
                            <Stack style={{ width: '70%', alignItems: 'center', fontSize: '2.5em' }}>
                                {options.map((o, i) => {
                                    const checked = existingAnswers && existingAnswers.length === 1 && existingAnswers[0].QuestionOptionId === o.Id;
                                    return <FormControlLabel sx={{ width: '100%', backgroundColor: 'transparent', color: 'white', fontSize: '2em' }} key={o.Id} control={<Checkbox id={o.Id.toString()} onChange={singleSelectChecked} sx={{ '& .MuiSvgIcon-root': { fontSize: '1em', color: 'white' } }} checked={checked} value={checked} />} label={<span className={'multiCheckboxLabel'}>{o.Text}</span>} />
                                }
                                )}
                            </Stack>
                        );
                    }
                    break;
                case QuestionType.MultipleChoice:
                    options = currentQuestion.Options;
                    console.log('Multiple Choice');
                    if (options) {
                        return (
                            <Stack style={{ width: '70%', height: 'auto', alignItems: 'center' }}>
                                {options.map((o) => {
                                    const selected = existingAnswers && existingAnswers.length === 1 && existingAnswers[0].QuestionOptionId === o.Id;
                                    return <button id={o.Id.toString()} key={o.Id} className={`whiteMultipleChoiceButton ${selected ? 'selected' : ''}`} onClick={selectOptionHandler}>{o.Text}</button>
                                }
                                )}
                            </Stack>
                        );
                    }
                    break;
                case QuestionType.TextArea:
                    console.log('Text Area')
                    const existingAnswer = (existingAnswers && existingAnswers.length > 0) ? existingAnswers[0].EnteredText : '';
                    console.dir(existingAnswer);
                    return (
                        <>
                            <TextareaAutosize style={{ fontSize: '4em', width: '70%', height: 'calc(var(--bodyHeight) * .30px' }} id={'textArea'} value={existingAnswer} onChange={textEntered} />
                            <div style={{ marginTop: "1em", width: '70%' }}>
                                <Keyboard
                                    theme={"hg-theme-default keyboardTheme"}
                                    layout={keyboardLayout}
                                    mergeDisplay
                                    display={keyboardDisplay}
                                    layoutName={keyboardLayoutName}
                                    onChange={onChangeHandler}
                                    onKeyPress={onKeyPressHandler}
                                />
                            </div>
                        </>
                    );
            }
        }
    }, [currentQuestion, answers, multiSelectItemChecked, selectOptionHandler, singleSelectChecked, textEntered, keyboardLayoutName, onChangeHandler, onKeyPressHandler])

    const questionText = useMemo(() => {
        if (currentQuestion) {
            if (currentQuestion.Text.charAt(0) === '!') {
                const parts = currentQuestion.Text.split('!');
                console.dir(parts);
                return (
                    <>
                        <p>{parts[1].trim()}</p>
                        <ul>
                            <li>{parts[2].trim()}</li>
                            <li>{parts[3].trim()}</li>
                            <li>{parts[4].trim()}</li>
                        </ul>
                        <p>{parts[5].trim()}</p>
                    </>
                );
            }
            return currentQuestion.Text;
        }
    }, [currentQuestion]);


    return (
        <Stack className={'questionContainer'} style={{ color: 'white' }} alignItems={'center'}>
            <h1 style={{ marginBottom: 0, textAlign: "center" }}>Question {questionIndex}</h1>
            {currentQuestion && currentQuestion.Header && <h2 style={{ width: '85%', textAlign: 'center' }}>{currentQuestion && currentQuestion.Header}</h2>}
            <h3 style={{ width: '80%', textAlign: 'center' }}>{questionText}</h3>
            {renderedQuestion}
            {alertText && <Alert style={{width: '80%', color: '#05314D', fontSize: '2em'}} severity={'warning'}>{alertText}</Alert>}
            <div style={{ width: '60%', height: '10%', display: 'flex', alignItems: 'center', justifyItems: 'center', margin: '0 auto' }}>
                <Button fullWidth onClick={handleBack}>
                    <img className={'standardButton'} alt={'Back'} src={BackButton} />

                </Button>
                <Button fullWidth onClick={handleNext}>
                    <img className={'standardButton'} alt={'Next'} src={NextButton} />
                </Button>
            </div>
        </Stack>

    );
}