import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { fadeIn, fadeOut } from "../features/video/videoSlice";

// import topicApi from '../assets/topicSelect/topicSelectAPI.png';
// import topicApiClicked from '../assets/topicSelect/topicSelectAPIClicked.png';

// import topicProduct from '../assets/topicSelect/topicSelectProduct.png';
// import topicProductClicked from '../assets/topicSelect/topicSelectProductClicked.png';

import topicRenter from '../assets/topicSelect/topicSelectRenter.png';
import topicRenterClicked from '../assets/topicSelect/topicSelectRenterClicked.png';

// import topicRisk from '../assets/topicSelect/topicSelectRisk.png';
// import topicRiskClicked from '../assets/topicSelect/topicSelectRiskClicked.png';
import { Stack } from "@mui/material";
import { useDispatch } from "react-redux";

export function TopicSelect() {
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const [clickedTopic, setClickedTopic] = useState(0);

    useEffect(() => {
        dispatch(fadeOut({}) as any);
    }, [dispatch])

    const clickHandler = useCallback((e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        const topicId = e.currentTarget.id;
        setClickedTopic(parseInt(topicId));
        dispatch(fadeIn(undefined) as any).then(() => {
            navigator(`/topicSummary/${topicId}`);
        });
    }, [dispatch, navigator]);

    return (
        <Stack style={{width: '100%', height: '45%'}} alignContent={'center'} alignItems={'center'}>
            
            <div className="topicLogoContainer" style={{marginTop: '20%', width: 'calc(var(--bodyWidth) * .43px)', height: 'calc(var(--bodyHeight) * .1px)'}} />
            <span className="selectTopic">Select a Topic:</span>
            <div className="topicCityScape">
            </div>
            <div>
                <div id={'1'} className="topicAPI" onClick={clickHandler}>
                    <div className="topicTextRelative">{['API', <br/>, 'Strategy']}</div>
                    {clickedTopic !== 1 ? <img alt={'Topic API Strategy'} src={topicRenter} /> : <img alt={'Topic API Strategy Selected'} src={topicRenterClicked} />}
                </div>
                <div id={'4'} className="topicProducts" onClick={clickHandler}>
                    <span className="topicTextRelative">Product Awareness and Attitudes</span>
                    {clickedTopic !== 4 ? <img alt={'Topic Product Awareness and Attitudes'} src={topicRenter} /> : <img alt={'Topic Product Awareness and Attitudes Selected'} src={topicRenterClicked} />}
                </div>
                <div id={'3'} className="topicRenter" onClick={clickHandler}>
                    <span className="topicTextRelative">Renter Research & Insights</span>
                    {clickedTopic !== 3 ? <img alt={'Renter Research & Insights'} src={topicRenter} /> : <img alt={'Renter Research & Insights Selected'} src={topicRenterClicked} />}
                </div>
                <div id={'2'} className="topicRisk" onClick={clickHandler}>
                    <span className="topicTextRelative">{['Risk', <br />, 'Management']}</span>
                    {clickedTopic !== 2 ? <img alt={'Risk Management'} src={topicRenter} /> : <img alt={'Risk Management Selected'} src={topicRenterClicked} />}
                </div>
            </div>
        </Stack>
    );
}


export default TopicSelect;