export enum VideoIndexes {
    IntroLoop = 0,
    Welcome = 1,
    Question1 = 2,
    Question2 = 3,
    Question3 = 4,
    Question4 = 5,
    Question5 = 6
}

export interface Lender {
    Id: number;
    Priority: number;
    Name: string;
    Description: string;
}

export interface Topic {
    Id: number;
    Priority: number;
    Name: string;
    Description: string;
    Active: boolean;
    Questions?: Question[];
}

export enum QuestionType {
    MultipleChoice = 1,
    TextArea = 2,
    CheckboxMultiSelect = 3,
    CheckboxSingleSelect = 4
}

export interface Question {
    Id: number;
    TopicId: number;
    Priority: number;
    Type: QuestionType;
    Header?: string;
    Text: string;
    Active: boolean;
    Options?: QuestionOption[];
}

export interface QuestionOption {
    Id: number;
    QuestionId: number;
    Priority: number;
    Text: number;
    Active: boolean;
}


export const MockLenders = [
        {
            id: 1,
            priority: 1,
            name: "Arbor",
            description: null
        },
        {
            id: 2,
            priority: 2,
            name: "Basis",
            description: null
        },
        {
            id: 3,
            priority: 3,
            name: "Berkadia",
            description: null
        },
        {
            id: 4,
            priority: 4,
            name: "BWE",
            description: null
        },
        {
            id: 5,
            priority: 5,
            name: "Capital One",
            description: null
        },
        {
            id: 6,
            priority: 6,
            name: "CBRE",
            description: null
        },
        {
            id: 7,
            priority: 7,
            name: "Citi",
            description: null
        },
        {
            id: 8,
            priority: 8,
            name: "Colliers",
            description: null
        },
        {
            id: 9,
            priority: 9,
            name: "Grandbridge",
            description: null
        },
        {
            id: 10,
            priority: 10,
            name: "Greystone",
            description: null
        },
        {
            id: 11,
            priority: 11,
            name: "HomeStreet",
            description: null
        },
        {
            id: 12,
            priority: 12,
            name: "JLL",
            description: null
        },
        {
            id: 13,
            priority: 13,
            name: "JP Morgan Chase",
            description: null
        },
        {
            id: 14,
            priority: 14,
            name: "Keybank",
            description: null
        },
        {
            id: 15,
            priority: 15,
            name: "Lument",
            description: null
        },
        {
            id: 16,
            priority: 16,
            name: "M&T",
            description: null
        },
        {
            id: 17,
            priority: 17,
            name: "Newmark",
            description: null
        },
        {
            id: 18,
            priority: 18,
            name: "Newpoint",
            description: null
        },
        {
            id: 19,
            priority: 19,
            name: "Northmarq",
            description: null
        },
        {
            id: 20,
            priority: 20,
            name: "PGIM",
            description: null
        },
        {
            id: 21,
            priority: 21,
            name: "PNC",
            description: null
        },
        {
            id: 22,
            priority: 22,
            name: "Regions",
            description: null
        },
        {
            id: 23,
            priority: 23,
            name: "Walker & Dunlop",
            description: null
        },
        {
            id: 24,
            priority: 24,
            name: "Wells Fargo",
            description: null
        }
    ];