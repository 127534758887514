import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { RootState } from "../app/store";
import { useCallback, useEffect, useState } from "react";
import { Topic, VideoIndexes } from "../features/common/models";
import { fadeIn, fadeOut, play, reset } from "../features/video/videoSlice";
import { Button, Stack } from "@mui/material";
import NextButton from '../assets/nextButton.png';
import BackButton from '../assets/backButton.png';

export function TopicSummary() {
    const { topicId } = useParams();
    const { topics } = useSelector((s: RootState) => s.topic);
    const [topicIndexNumber, setTopicIndexNumber] = useState(0);
    const [currentTopic, setCurrentTopic] = useState<Topic | undefined>(undefined);
    const dispatch = useDispatch();
    const navigator = useNavigate();

    useEffect(() => {
        if (topicId) {
            dispatch(fadeOut({}) as any);
            setTopicIndexNumber(parseInt(topicId));
        }
    }, [dispatch, topicId]);

    useEffect(() => {
        if (topicIndexNumber && topics.length > 0) {
            const topic = topics.find(t => t.Id === topicIndexNumber);
            if (topic) {
                setCurrentTopic(topic);
            }

        }
    }, [topics, topicIndexNumber]);

    const handleBack = useCallback(() => {
        dispatch(reset());
        navigator('/topicSelect');
    }, [navigator, dispatch]);

    const handleNext = useCallback(() => {
        return dispatch(fadeIn('welcome') as any).then(() => {
            // dispatch(show());
            dispatch(play(VideoIndexes.Question1));
            navigator(`/questionnaire/${topicId}/1`);
        });

    }, [navigator, dispatch, topicId])

    return (
        <Stack className={'flexContainer summaryContainer'} style={{
            fontFamily: 'SourceSansVariableRoman',
            color: 'white',
            margin: '0 auto',
            textAlign: 'center'
        }}>
            <div
                className="flexHeader"
                style={{
                    height: "10%",
                    fontFamily: "SourceSansVariableRoman",
                    color: "white",
                }}
            >
                <Stack className={'summaryContainer'}>
                    <div>
                        <h1 style={{marginBottom: 0}}>Topic:</h1>
                    </div>
                    <div>
                        <h2 style={{marginTop: 0, marginLeft: '2em', marginRight: '2em'}}>{currentTopic && currentTopic.Name}</h2>
                    </div>
                    <div className={'topicDescription'}>{currentTopic && currentTopic.Description}</div>
                    <div style={{ width: '60%', height: '10%', display: 'flex', alignItems: 'center', justifyItems: 'center', margin: '0 auto' }}>
                        <Button fullWidth onClick={handleBack}>
                            <img className={'standardButton'} alt={'Back'} src={BackButton} />

                        </Button>
                        <Button fullWidth onClick={handleNext}>
                            <img className={'standardButton'} alt={'Next'} src={NextButton} />
                        </Button>
                    </div>
                </Stack>

            </div>



        </Stack>
    )
}

export default TopicSummary;