import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Lender } from './models';

export interface Configuration {
  Id: number;
  Key: string;
  Value: string;
}

export interface Entry {
  Id?: number;
  FullName?: string;
  LenderId?: number;
  Position?: string;
  EntryAnswers: Record<number, EntryAnswer[]>;
}

export interface EntryAnswer {
  Id?: number;
  EntryId?: number;
  QuestionId: number;
  QuestionOptionId?: number;
  EnteredText?: string;
}

export interface CommonState {
  entry?: Entry;
  config?: Configuration[];
  lenders?: Lender[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CommonState = {
  status: 'idle',
};

export interface SaveEntryRequest {
  entry: Entry;
  additionalAnswers: EntryAnswer[];
}

export const loadConfig = createAsyncThunk(
  'common/config',
  async () => {
    const endpoint = '/data-api/rest/Config';
    const response = await fetch(endpoint, {
      method: 'GET'
    });
    if (response.ok) {
      const configs = await response.json();
      return configs.value;
    }
    
  }
);

export const loadLenders = createAsyncThunk(
  'common/lenders',
  async () => {
    const endpoint = "/data-api/rest/Lenders";
    const response = await fetch(endpoint);
    if (response.ok) {
      const lenders = await response.json();
      return lenders.value;
    }
    // try {
    //       const data = await response.json();
    //       setLenders(data.value);
    //   } catch (e) {
    //       const mockData = MockLenders;
    //       setLenders(mockData as any);
    //   }
  }
);

export const saveEntryAsync = createAsyncThunk(
  'common/saveEntry',
  async (entryRequest: SaveEntryRequest) => {
    const { entry, additionalAnswers } = entryRequest;
    const endpoint = '/data-api/rest/Entry/';
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Id: entry.Id,
        FullName: entry.FullName,
        LenderId: entry.LenderId,
        Position: entry.Position
      })
    });
    // Entry Answers
    const answerEndpoint = '/data-api/rest/EntryAnswer/'
    const result = await response.json();
    const { Id } = result.value[0];
    if (Id) {
      const answerPayload: EntryAnswer[] = [];

      for (const ans in entry.EntryAnswers) {
        answerPayload.push(...entry.EntryAnswers[ans].map((ans) => {
          return {
            ...ans,
            EntryId: Id
          }
        }))
      };

      answerPayload.push(...additionalAnswers.map((ans) => {
        return {
          ...ans,
          EntryId: Id
        }
      }));

      console.dir(answerPayload);

      answerPayload.forEach(async (ap) => {
        if (ap) {
          const answerResponse = await fetch(answerEndpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(ap)
          });
          console.dir(answerResponse);
        }
      });
    }
  }
)

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount: number) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    createNewEntry: (state, action: PayloadAction<{ lenderId?: number, position?: string }>) => {
      const entry = { LenderId: action.payload.lenderId, Position: action.payload.position, EntryAnswers: [] };
      state.entry = entry;
      localStorage.setItem('currentEntry', JSON.stringify(entry));
    },
    setEntryFromStorage: (state) => {
      const storedValue = localStorage.getItem('currentEntry');
      if (storedValue) {
        state.entry = JSON.parse(storedValue);
      }
    },
    resetAnswer: (state, action: PayloadAction<number>) => {
      if (state.entry) {
        state.entry.EntryAnswers[action.payload] = [];
        //localStorage.removeItem('currentEntry');
      }
    },
    submitAnswer: (state, action: PayloadAction<EntryAnswer>) => {
      if (!state.entry) {
        const storedValue = localStorage.getItem('currentEntry');
        if (storedValue) {
          state.entry = JSON.parse(storedValue);
        }
      }
      if (state.entry) {
        if (!(action.payload.QuestionId in state.entry.EntryAnswers)) state.entry.EntryAnswers[action.payload.QuestionId] = [];
        state.entry.EntryAnswers[action.payload.QuestionId].push(action.payload);
        localStorage.setItem('currentEntry', JSON.stringify(state.entry));
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveEntryAsync.fulfilled, (state) => {

      })
      .addCase(loadConfig.fulfilled, (state, action: PayloadAction<any>) => {
        state.config = action.payload;
      })
      .addCase(loadLenders.fulfilled, (state, action: PayloadAction<any>) => {
        state.lenders = action.payload;
      })
  }
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(incrementAsync.pending, (state) => {
  //         state.status = 'loading';
  //       })
  //       .addCase(incrementAsync.fulfilled, (state, action) => {
  //         state.status = 'idle';
  //         state.value += action.payload;
  //       })
  //       .addCase(incrementAsync.rejected, (state) => {
  //         state.status = 'failed';
  //       });
  //   },
});

export const { createNewEntry, setEntryFromStorage, submitAnswer, resetAnswer } = commonSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCommon = (state: RootState) => state.common;

export const selectCurrentQuestion = (state: RootState, topicId: string | undefined, questionIndex: string | undefined) => {
  if (topicId && questionIndex) {
    const { hydratedTopics } = state.topic;
    if (hydratedTopics) {
      const topic = hydratedTopics.find(t => t.Id === parseInt(topicId));
      if (topic && topic.Questions) {
        return topic.Questions[parseInt(questionIndex) - 1];
      }
    }
  }
  return undefined;
}

export default commonSlice.reducer;
